import React from "react";
import { Grid, Avatar, createStyles, Text } from "@mantine/core";
import hero from "./profile1.jpg";

const useStyles = createStyles((theme, _params, getRef) => ({
  avatarbox: {
    backgroundColor: "#F4F5F5",
    color: "black",
    padding: "7px 7px",
    borderRadius: "10px",
    marginBottom: "3vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    ":hover": {
      backgroundColor: "#d4cece",
    },
  },
}));

const AvatarBox = ({ name }) => {
  const { classes } = useStyles();
  return (
    <>
      <Grid.Col span={2}>
        <Avatar color="cyan" radius="xl">
          {name?.slice(0, 2)}
        </Avatar>
        <Text size="lg" weight={600}>
          {name}
        </Text>
      </Grid.Col>
    </>
  );
};

export default AvatarBox;
