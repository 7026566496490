import { SimpleGrid, Space } from "@mantine/core";
import React, { useState } from "react";
import { useQuery } from "react-query";
import Dashboard from "../components/Dashboard/Dashboard";
import EmployeeCard from "../components/Employee/EmployeeCard";
import EmployeeDrawer from "../components/Employee/EmployeeDrawer";
import EmpSection from "../components/Employee/EmpSection";
import RoleModal from "../components/Employee/RoleModal";
import RosterModal from "../components/Employee/RosterModal";
import { EMPLOYEE_ROUTE } from "../utils/ApiRoutes";
import { FetchQuery } from "../utils/QueryCalls";

const EmployeePage = () => {
  const [opened, setOpened] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [value, setValue] = useState("active");
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [searchVal, setSearchVal] = useState("");
  const { data: activeEmployees } = useQuery(["activeEmployees"], () =>
    FetchQuery(EMPLOYEE_ROUTE)
  );
  const { data: allEmployees } = useQuery(["employees"], () =>
    FetchQuery(EMPLOYEE_ROUTE + "/all")
  );

  const handleDrawerClose = () => {
    setSelectedEmployee(null);
    setOpened(false);
  };

  return (
    <Dashboard>
      <div style={{ marginLeft: "5rem" }}>
        <EmpSection
          value={value}
          setValue={setValue}
          setSearchVal={setSearchVal}
        />
      </div>
      <Space h={22} />
      <SimpleGrid
        cols={5}
        breakpoints={[
          { maxWidth: 980, cols: 3, spacing: "md" },
          { maxWidth: 755, cols: 2, spacing: "sm" },
          { maxWidth: 600, cols: 1, spacing: "sm" },
        ]}
      >
        {value === "active" ? (
          <>
            {activeEmployees
              ?.filter((e) =>
                e?.employee?.name
                  ?.toLowerCase()
                  .includes(searchVal.toLowerCase())
              )
              .map((employee) => (
                <EmployeeCard
                  key={employee.id}
                  setOpened={setOpened}
                  employee={employee}
                  setSelectedEmployee={setSelectedEmployee}
                />
              ))}
          </>
        ) : (
          <>
            {allEmployees
              ?.filter((e) =>
                searchVal === "" && e?.employee?.name === null
                  ? true
                  : e?.employee?.name
                      ?.toLowerCase()
                      .includes(searchVal.toLowerCase())
              )
              .map((employee) => (
                <EmployeeCard
                  key={employee.id}
                  setOpened={setOpened}
                  employee={employee}
                  setSelectedEmployee={setSelectedEmployee}
                />
              ))}
          </>
        )}
      </SimpleGrid>
      <Space h={18} />
      <EmployeeDrawer
        opened={opened}
        handleDrawerClose={handleDrawerClose}
        selectedEmployee={selectedEmployee}
        setRoleModal={setRoleModal}
      />
      {opened && <RosterModal selectedEmployee={selectedEmployee} />}
      {roleModal && (
        <RoleModal
          selectedEmployee={selectedEmployee}
          roleModal={roleModal}
          setRoleModal={setRoleModal}
        />
      )}
    </Dashboard>
  );
};

export default EmployeePage;
