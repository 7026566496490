import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import LocationPage from "./Pages/LocationPage";
import RoasterPage from "./Pages/RoasterPage";
import SettingPage from "./Pages/SettingPage";
import TimeSheetPage from "./Pages/TimeSheetPage";
// import Login from "./Pages/Login";
import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { X } from "tabler-icons-react";
import { AppContext } from "./context/AppContext";
import EmployeePage from "./Pages/EmployeePage";
import Testpage from "./Pages/Testpage";
import { AutoLoginUser } from "./utils/ApiCalls";
import ProtectedRoute from "./utils/ProtectedRoute";
import { checkTokenCookie } from "./utils/utils";
import Message from "./Pages/Message";
import DashboardPage from "./Pages/DashboardPage";

const Login = React.lazy(() => import("./Pages/Login"));
const AppRoute = () => {
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(AppContext);
  const navigation = useNavigate();

  useEffect(() => {
    CheckUser();
  }, []);

  const CheckUser = async () => {
    try {
      setLoading(true);
      if (checkTokenCookie() === false) {
        setLoading(false);
        return;
      }
      const data = await AutoLoginUser();
      setUser(data);
      console.log("called");
      navigation(window.location.pathname);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showNotification({
        title: "Error",
        message: e,
        color: "red",
        icon: <X />,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      {loading ? (
        <Center style={{ height: "100vh" }}>
          <Loader size={"lg"} />;
        </Center>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="app" element={<ProtectedRoute />}>
            <Route index element={<Navigate to="/app/dashboard" replace />} />
            <Route index path="dashboard" element={<DashboardPage />} />
            <Route path="employee" element={<EmployeePage />} />
            <Route path="home" element={<HomePage />} />
            <Route path="roster" element={<RoasterPage />} />
            <Route path="timesheet" element={<TimeSheetPage />} />
            <Route path="location" element={<LocationPage />} />
            <Route path="setting" element={<SettingPage />} />
            <Route path="message" element={<Message />} />
            <Route path="test" element={<Testpage />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default AppRoute;
