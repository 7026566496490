import { Grid, Group, SimpleGrid, Stack } from "@mantine/core";
import React from "react";
import Dashboard from "../components/Dashboard/Dashboard";
import EmployeeList from "../components/Dashboard/EmployeeList";
import NotificationContainer from "../components/Dashboard/NotificationContainer";
import WordpressForms from "../components/Dashboard/WordpressForms";

const DashboardPage = () => {
  return (
    <Dashboard>
      <Grid grow>
        <Grid.Col span={8} spacing="xs">
          <EmployeeList />
          <WordpressForms />
        </Grid.Col>
        <NotificationContainer />
      </Grid>
    </Dashboard>
  );
};

export default DashboardPage;
