import { Button, createStyles, Grid } from "@mantine/core";
import dayjs from "dayjs";
import React from "react";

// import TimelineComp from '../components/Roaster/TimelineComp';

const useStyles = createStyles((theme, _params, getRef) => ({
  rightheader: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    backgroundColor: "#001D6E",
    color: "white",
    padding: "10px 20px",
    borderBottomLeftRadius: "20px",
    borderTopLeftRadius: "20px",
    marginBottom: "1.5em",
  },
  gridMain: {
    border: "1px solid lightgray",
    width: "100%",
  },
  unselectedCol: {
    borderLeft: "1px solid lightgray",
  },
  gridDay: {
    fontSize: "34px",
    textAlign: "center",
  },
  gridDate: {
    fontSize: "20px",
    textAlign: "center",
  },
}));

const RightHeader = (props) => {
  const { classes } = useStyles();
  let val = dayjs(props.date.start).format("DD");
  return (
    <Grid
      grow
      className={classes.gridMain}
      columns={17}
      style={{ width: "100%" }}
    >
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {" "}
          {dayjs(props.date.start).format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(1, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(1, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(2, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(2, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(3, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(3, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(4, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(4, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(5, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(5, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(6, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(6, "d").format("DD")}
        </p>
      </Grid.Col>
    </Grid>
  );
};

export default RightHeader;
