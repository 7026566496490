import React, {useState} from 'react';
import { TextInput, Grid, createStyles, Select, Modal, Text, Button, Space, ScrollArea, Indicator } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { Search, Location, Copy, CaretLeft, CaretRight,  Plus } from 'tabler-icons-react';
import Dashboard from '../components/Dashboard/Dashboard';
import Notification from '../components/Home/Notification';

const useStyles = createStyles((theme, _params, getRef) => ({
    badge:{
        height: '2.5em',
        paddingTop: '0px'
    },
    container:{
        marginBottom: "2em"
    },

    togglebutton:{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "0.3em"
    },
    togglebtncnt: {
        backgroundColor: "#f0ede9"
    },
    careleft:{
        background: "#d1cfcb",
        borderRadius: "50%",
    },
    title:{
        backgroundColor: "#F32424",
        color: "white",
        padding: "5px 20px",
        borderRadius: "20px",
        marginBottom: "1.7em",
        textAlign: "center"
    
    },

    rightheader :{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#001D6E",
        color: "white",
        padding: "10px 20px",
        borderBottomLeftRadius: "20px",
        borderTopLeftRadius: "20px",
        marginBottom: "1.5em",
        

    },
    
    roastersection : {
        display: "flex",
        justifyContent: "space-between"
    },
    leftsection: {
        flexBasis: "17%",

        // border: "1px solid black"
    },
    rightsection: {
        flexBasis: "81%",
        // border: "1px solid black"
    },
    rightcontent: {
        paddingRight:"1em",

    }
  }));


const RoasterPage = () => {
    const { classes } = useStyles();
    const [opened, setOpened] = useState(false);
    const [openedCalendar, setOpenedCalender] = useState(false);
    const [value, setValue] = useState(null);

  return (
    <Dashboard>
     <Notification />
      
    </Dashboard>
    
  )
}

export default RoasterPage;