/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStyles,
  Grid,
  Group,
  Modal,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useQueryClient } from "react-query";
import { useAddLocation } from "./services/usePostData";
import { useUpdateLocation } from "./services/useUpdateData";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import axios from "axios";

const useStyles = createStyles((theme, _params, getRef) => ({
  iframe: {
    border: "none",
    borderRadius: "1rem",
  },
}));

const AddModal = ({ opened, setOpened, selectedIndex }) => {
  //for google map
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(["locations"]);
  const currentLocation = useRef(null);
  const [value, setValue] = useState(null);

  const { classes } = useStyles();
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [mapUrl, setMapUrl] = useState(`https://maps.google.com/maps?q=${latLng.lat},${latLng.lng}&hl=es;&output=embed`);

  const form = useForm({
    initialValues: {
      name: "",
      location: "",
      lat: "",
      lng: "",
    },
  });
  useEffect(() => {
    if (selectedIndex.current) {
      currentLocation.current = data.find(
        (e) => e.id === selectedIndex.current
      );
      form.setFieldValue("location", currentLocation.current?.location);
      form.setFieldValue("name", currentLocation.current?.name);
      form.setFieldValue("lat", currentLocation?.current?.lat);
      form.setFieldValue("lng", currentLocation?.current?.lng);
      setMapUrl(`https://maps.google.com/maps?q=${currentLocation.current?.lat},${currentLocation.current?.lng}&hl=es;&output=embed`);
    }
  }, [selectedIndex.current, data]);

  const { mutate } = useAddLocation();
  const { mutate: mutateUpdate } = useUpdateLocation();

  const handleAdd = (e) => {
    e.preventDefault();
    mutate(form.values, {
      onSuccess: () => {
        setOpened(false);
      },
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();


    console.log(form.values);
    mutateUpdate(
      { data:form.values , id: currentLocation.current?.id },
      {
        onSuccess: () => {
          setOpened(false);
        },
      }
    );
  };

  const handlePlaceSelect = async (data) => {
    console.log(data);
    setValue(data);
    const geo = await geocodeByPlaceId(data.value.place_id);
    const latLng = await getLatLng(geo[0]);
    setLatLng(latLng);
    form.setFieldValue("location", data.label);
    form.setFieldValue("lat", latLng.lat.toString());
    form.setFieldValue("lng", latLng.lng.toString());
    setMapUrl(`https://maps.google.com/maps?q=${latLng.lat},${latLng.lng}&hl=es;&output=embed`)
  };

  return (
    <Modal
      size={1000}
      opened={opened}
      radius="lg"
      onClose={() => {
        setOpened(false);
        selectedIndex.current = null;
      }}
      title="Locations"
    >
      <Grid>
        <Grid.Col span={6}>
          <form>
            <TextInput
              my="md"
              label="Name"
              placeholder="Name"
              {...form.getInputProps("name")}
            />
            <GooglePlacesAutocomplete
              apiKey="AIzaSyCa5vw2NRJC1Da8ZelHXoc0kb3-OoljUAU"
              selectProps={{
                value,
                onChange: handlePlaceSelect,
              }}
            />

            <Group position="left" mt="xl">
              <Button
                type="Search"
                size="md"
                style={{ backgroundColor: "#30007F", color: "white" }}
                onClick={selectedIndex.current ? handleEdit : handleAdd}
                disabled={
                  form.values.location === "" || form.values.name === ""
                }
              >
                {selectedIndex.current ? "Edit" : "Submit"}
              </Button>
            </Group>
          </form>
        </Grid.Col>
        <Grid.Col span={6}>
          <iframe
            className={classes.iframe}
            src={mapUrl}
            title="Location"
            id="iframeId"
            height="300px"
            width="100%"
          ></iframe>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default AddModal;
