import {
  Accordion,
  Anchor,
  Group,
  Image,
  Modal,
  Table,
  Text,
} from "@mantine/core";
import React from "react";
import { useQuery } from "react-query";
import { apiUrlAbsolute, PROFILE_ROUTE } from "../../utils/ApiRoutes";
import { FetchQuery } from "../../utils/QueryCalls";

const DocumentModal = ({
  documentModal,
  setDocumentModal,
  selectedEmployee,
}) => {
  const { data } = useQuery(
    ["emp_status"],
    () => FetchQuery(PROFILE_ROUTE + `/${selectedEmployee}/status`),
    {
      enabled: !!selectedEmployee,
    }
  );
  return (
    <Modal
      opened={documentModal}
      onClose={() => setDocumentModal(false)}
      size="xl"
    >
      <Accordion>
        <Accordion.Item label="Bank">
          {data?.bank ? (
            <Table>
              <thead>
                <tr>
                  <th>Bank Name</th>
                  <th>Full Name</th>
                  <th>ACC No</th>
                  <th>BSB No</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <>
                    <td>{data?.bank?.name}</td>
                    <td>{data?.bank?.full_name}</td>
                    <td>{data?.bank?.acc_no}</td>
                    <td>{data?.bank?.bsb_no}</td>
                  </>
                </tr>
              </tbody>
            </Table>
          ) : (
            <td>No data</td>
          )}
        </Accordion.Item>
        <Accordion.Item label="Tax">
          {data?.tax ? (
            <Text>
              File no: <Text component="span">{data?.tax?.tax_file_no}</Text>
            </Text>
          ) : (
            "No data"
          )}
        </Accordion.Item>
        <Accordion.Item label="Documents">
          <Group position="apart">
            {data?.documents?.identification?.split(".").pop() === "pdf" ? (
              <Anchor
                target={"_blank"}
                href={`${PROFILE_ROUTE}/identification/${data?.documents?.identification}`}
              >
                View PDF
              </Anchor>
            ) : (
              <Image
                radius="md"
                src={`${PROFILE_ROUTE}/identification/${data?.documents?.identification}`}
                alt="No document uploaded"
                width={200}
              />
            )}
            {data?.documents?.additionalId?.split(".").pop() === "pdf" ? (
              <Anchor
                target={"_blank"}
                href={`${PROFILE_ROUTE}/additionalId/${data?.documents?.additionalId}`}
              >
                View PDF
              </Anchor>
            ) : (
              <Image
                radius="md"
                src={`${PROFILE_ROUTE}/additionalId/${data?.documents?.additionalId}`}
                alt="No document uploaded"
                width={200}
              />
            )}
          </Group>
        </Accordion.Item>
      </Accordion>
    </Modal>
  );
};

export default DocumentModal;
