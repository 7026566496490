import { useMutation, useQueryClient } from "react-query";
import { showNotification } from "@mantine/notifications";
import { DeleteQuery } from "../../../utils/QueryCalls";
import { SCHEDULE_ROUTE } from "../../../utils/ApiRoutes";


const DeleteSchedule = async (id) => {
    return await DeleteQuery(SCHEDULE_ROUTE + "/" + id)
}

export const useDeleteSchedule = () => {
    const queryClient = useQueryClient()

    return useMutation(DeleteSchedule, {
        onSuccess: () => {
            queryClient.invalidateQueries(['schedules'])
            showNotification({
                title: "Schedule removed",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Couldn't remove Schedule",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}