import axios from 'axios'
import { LOGINPAGE } from './Constants';

export const FetchQuery = async (route) => {
    try {
        const { data } = await axios.get(route, {
            withCredentials: true,
        });
        return data;
    } catch (e) {
        if (e.response.status === 401) {
            window.location.pathname = LOGINPAGE
        }
    }
}
export const PostQuery = async (route, body) => {
    try {
        console.log(JSON.stringify(body), 'bod')
        const { data } = await axios.post(route, JSON.stringify(body), {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return data;
    } catch (e) {
        if (e.response.status === 401) {
            window.location.pathname = LOGINPAGE
        }
        throw e;
    }
}
export const UpdateQuery = async (route, body) => {
    try {
        console.log(JSON.stringify(body), 'bod')
        const { data } = await axios.patch(route, JSON.stringify(body), {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return data;
    } catch (e) {
        if (e.response.status === 401) {
            window.location.pathname = LOGINPAGE
        }
        throw e;
    }
}
export const DeleteQuery = async (route) => {
    try {
        const { data } = await axios.delete(route, {
            withCredentials: true,
        });
        return data;
    } catch (e) {
        if (e.response.status === 401) {
            window.location.pathname = LOGINPAGE
        }
        throw e;
    }
}

//i'm just lazy now
export const DeleteWithBodyQuery = async (route, body) => {
    try {
        console.log(JSON.stringify(body), 'bod')
        const { data } = await axios.delete(route, {
            data: body,
            withCredentials: true,
        });
        return data;
    } catch (e) {
        // if (e.response.status === 401) {
        //     window.location.pathname = LOGINPAGE
        // }
        throw e;
    }
}
export const FetchQueryWithParams = async ({ queryKey }) => {
    try {
        const route = queryKey[1];
        const params = queryKey[2];
        const location_id = queryKey[3];
        const { data } = await axios.get(route + "/" + params + `&location_id=${location_id}`, {
            withCredentials: true,
        });
        return data;
    } catch (e) {
        if (e.response.status === 401) {
            window.location.pathname = LOGINPAGE
        }
    }
}
export const FetchTimeSheet = async ({ queryKey }) => {
    try {
        const route = queryKey[1];
        const params = queryKey[2];
        const emp_id = queryKey[3];
        const { data } = await axios.get(route + "/" + params + `&emp_id=${emp_id}`, {
            withCredentials: true,
        });
        return data;
    } catch (e) {
        if (e.response.status === 401) {
            window.location.pathname = LOGINPAGE
        }
    }
}
export const FetchMessages = async ({ queryKey }) => {
    try {
        const route = queryKey[1];
        const receiver_id = queryKey[2];
        const { data } = await axios.get(route + "/" + receiver_id, {
            withCredentials: true,
        });
        return data;
    } catch (e) {
        if (e.response.status === 401) {
            window.location.pathname = LOGINPAGE
        }
    }
}