import React from "react";
import  Dashboard  from "../components/Dashboard/Dashboard";
const SettingPage = () => {
    return ( 
      <Dashboard>
         <div>Noyhafaskdfjaklsd </div>
      </Dashboard>
     );
}
 
export default SettingPage;