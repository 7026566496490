import { Grid, Text, Button, createStyles, Container,Space } from '@mantine/core';
import { BoxPadding, Copy } from 'tabler-icons-react';

const useStyles = createStyles((theme, _params, getRef) =>({
   notification:{
    backgroundColor: "#464646",
    width:"18rem",
    borderRadius: '3em',
    padding: '1em 2em',
    display:'flex',
    alignItems: 'center'
   },
   iconpart:{
    marginRight: '1rem'

   },
   rightpart: {
    textAlign: 'cemter',
    color: 'white'
   }

}))
const Notification = (props) => {
    const {classes} = useStyles();

    return ( 
        <>
     <div className={classes.notification}>
        {/* <div className="classes notification"> */}
        <div className={classes.iconpart}>
            <Copy color='white' />
        </div>
        <div className={classes.rightpart}>
            <Text color={'white'}>Jack Request a Leave</Text>
            <Space h={1} />
            <Text color={'#E0E0E0'} size='sm'>18 NOV, 2022 - 10 AM</Text>
            <Space h='xs' />
            <Button size='xs' fullWidth radius="lg"> view </Button>
        </div>
     
      </div>

        </>

     );
}
 
export default Notification;