import React from "react";
import {AiOutlineSend} from "react-icons/ai";

import {
    ActionIcon,
    Avatar,
    createStyles,
    Grid,
    Input,
    Loader,
    ScrollArea,
    Text,
} from "@mantine/core";
import {getHotkeyHandler} from "@mantine/hooks";
import {useContext, useRef, useState} from "react";
import {ImSearch} from "react-icons/im";
import {useQuery} from "react-query";
import Dashboard from "../components/Dashboard/Dashboard";
import AvatarBox from "../components/Message/AvatarBox";
import LeaveRequestBox from "../components/Message/LeaveRequestBox";
import MessageBox from "../components/Message/MessageBox";
import {useSendMessage} from "../components/Message/services/SendMessage";
import {AppContext} from "../context/AppContext";
import {EMPLOYEE_ROUTE, MESSAGE_ROUTE} from "../utils/ApiRoutes";
import {FetchMessages, FetchQuery} from "../utils/QueryCalls";

const useStyles = createStyles((theme, _params, getRef) => ({
    //
}));

const Message = () => {
    const {user} = useContext(AppContext);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [currentEmployeeName, setCurrentEmployeeName] = useState("");
    const [messages, setMessages] = useState(null);
    const viewport = useRef();
    const [message, setMessage] = useState("");
    const [searchVal, setSearchVal] = useState("");

    const scrollToBottom = () =>
        setTimeout(() => {
            viewport.current.scrollTo({
                top: viewport.current.scrollHeight,
                behavior: "smooth",
            });
        }, 150);
    const {isLoading: employeeLoading, data: employees} = useQuery(
        ["employees"],
        () => FetchQuery(EMPLOYEE_ROUTE),
        {
            onSuccess: (data) => {
                console.log(currentEmployee);
                if (!currentEmployee) {
                    setCurrentEmployee(data[0].id);
                    getName(data[0].id);
                }
            },
        }
    );

    const {data} = useQuery(
        ["Messages", MESSAGE_ROUTE, currentEmployee],
        FetchMessages,
        {
            refetchInterval: 1500,
            enabled: !!currentEmployee,
            onSuccess: (data) => {
                if (messages === null && data.length > 0) {
                    setMessages(data);
                    scrollToBottom();
                }
            },
        }
    );

    const {mutate, isLoading: sendingMessage} = useSendMessage();
    const handleSend = () => {
        setMessage("");
        mutate(
            {id: currentEmployee, message: {message}},
            {
                onSuccess: () => {
                    scrollToBottom();
                },
            }
        );
    };

    const getName = (emp_id) => {
        const employee = employees.filter(
            (e) => e.id === (emp_id || currentEmployee)
        );
        console.log(employee);
        setCurrentEmployeeName(employee[0].employee.name);
    };

    return (
        <Dashboard>
            <Input
                id=""
                placeholder="Search Staff"
                icon={<ImSearch/>}
                radius="lg"
                style={{maxWidth: "270px"}}
                onChange={(e) => setSearchVal(e.target.value)}
            />
            <div>
                <div
                    style={{
                        display: "flex",
                        gap: "0.8rem",
                        marginTop: "1rem",
                    }}
                >
                    <div style={{width: "22%"}}>
                        <ScrollArea
                            type="auto"
                            style={{height: 620, width: 290}}
                            offsetScrollbars
                            scrollbarSize={12}
                            p={10}
                        >
                            <Grid mr="5px">
                                {!employeeLoading &&
                                    employees.length > 0 &&
                                    employees
                                        ?.filter((e) =>
                                            e?.employee.name
                                                ?.toLowerCase()
                                                .includes(searchVal.toLowerCase())
                                        )
                                        ?.map((emp) => (
                                            <AvatarBox
                                                key={emp?.id}
                                                name={emp?.employee?.name}
                                                active={currentEmployee === emp?.id}
                                                setCurrentEmployee={() => setCurrentEmployee(emp?.id)}
                                                empId={emp?.id}
                                                getName={getName}
                                            />
                                        ))}
                            </Grid>
                        </ScrollArea>
                    </div>
                    <div
                        style={{
                            background: "#E5E5E5",
                            width: "78%",
                            borderRadius: "18px",
                        }}
                    >
                        <div
                            style={{
                                border: "1px solid #0E0C56",
                                borderTopLeftRadius: "33px",
                                borderTopRightRadius: "33px",
                                background: " #0E0C56",
                                opacity: "0.8",
                            }}
                        >
                            <div
                                style={{
                                    padding: "0.3rem 0",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "1rem",
                                        paddingInline: "2rem",
                                    }}
                                >
                                    <Avatar src="" alt="it's me" radius="xl" color="cyan"/>
                                    <Text color="white">{currentEmployeeName}</Text>
                                </div>
                            </div>
                        </div>
                        <div style={{margin: "1rem 0"}}>
                            <ScrollArea
                                type="hover"
                                offsetScrollbars
                                scrollbarSize={5}
                                style={{
                                    height: 463,
                                }}
                                viewportRef={viewport}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.6rem",
                                    }}
                                >
                                    {data &&
                                        data.map((message) => {
                                            if (message.sender.role !== "SUPERADMIN") {
                                                if (message.type === "LEAVE") {
                                                    return (
                                                        <LeaveRequestBox
                                                            message={message.message}
                                                            status={message?.Leave?.status}
                                                            msgId={message.id}
                                                        />
                                                    );
                                                } else {
                                                    return <MessageBox message={message.message}/>;
                                                }
                                            } else {
                                                return (
                                                    <MessageBox message={message.message} left={false}/>
                                                );
                                            }
                                        })}
                                    {/* sender */}
                                </div>
                            </ScrollArea>
                        </div>
                        <div style={{padding: "0.5rem 1rem", marginTop: "1.1rem"}}>
                            <Input
                                radius={"lg"}
                                placeholder="Type a message..."
                                rightSectionWidth={70}
                                rightSection={
                                    sendingMessage ? (
                                        <Loader size={"sm"}/>
                                    ) : (
                                        <ActionIcon style={{zIndex: 1000}} onClick={handleSend}>
                                            <AiOutlineSend/>
                                        </ActionIcon>
                                    )
                                }
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={getHotkeyHandler([["Enter", handleSend]])}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    );
};

export default Message;
