import { showNotification } from "@mantine/notifications";
import axios from 'axios';
import { useMutation, useQueryClient } from "react-query";
import { EMPLOYEE_ROUTE } from "../../../utils/ApiRoutes";
import {DeleteQuery, PostQuery} from "../../../utils/QueryCalls";

const deleteUser = async (data) => {
    return await DeleteQuery(EMPLOYEE_ROUTE + "/" + data.id)
}
export const useDeleteUser = () => {
    const queryClient = useQueryClient()

    return useMutation(deleteUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(['activeEmployees']);
            queryClient.invalidateQueries(['employees']);
            showNotification({
                title: "Deleted employee",
                color: 'green'
            })
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}
