/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Select } from "@mantine/core";
import React from "react";
import { useState } from "react";
import { useChangeRole } from "./services/useRole";
function RoleModal({ selectedEmployee, roleModal, setRoleModal }) {
  const [selectedRole, setSelectedRole] = useState("");
  const { mutate } = useChangeRole();

  const handleRoleSelection = (val) => {
    setSelectedRole(val);
  };

  const handleSubmit = () => {
    mutate(
      { role: selectedRole, employeeId: selectedEmployee },
      {
        onSuccess: () => setRoleModal(false),
      }
    );
  };
  return (
    <Modal
      opened={roleModal}
      onClose={() => setRoleModal(false)}
      title="Edit Role"
      size={"md"}
    >
      <Select
        required
        label="Role"
        placeholder="Pick one"
        data={[
          { value: "ADMIN", label: "Admin" },
          { value: "MANAGER", label: "Manager" },
          { value: "EMPLOYEE", label: "Employee" },
        ]}
        onChange={handleRoleSelection}
      />
      <Button mt={3} onClick={handleSubmit}>
        Submit
      </Button>
    </Modal>
  );
}
export default RoleModal;
