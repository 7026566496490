import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import { MESSAGE_ROUTE } from "../../../utils/ApiRoutes";
import { PostQuery } from "../../../utils/QueryCalls";


const LeaveRequest = async ({ id, accept }) => {
    const req = accept ? "/approve" : "/reject";
    return await PostQuery(MESSAGE_ROUTE + "/" + id + req)
}

export const useLeaveRequest = () => {
    const queryClient = useQueryClient()

    return useMutation(LeaveRequest, {
        onSuccess: (data) => {
            console.log(data)
            queryClient.invalidateQueries(['Messages'])
            showNotification({
                title: data.action === "accepted" ? "Leave request accepted" : "Leave request rejected",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Could not send message",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}