import { Button, createStyles, Grid } from "@mantine/core";
import { useId } from "@mantine/hooks";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
// import hero from './hero.png';

const useStyles = createStyles((theme, _params, getRef) => ({
  cellbox: {
    backgroundColor: "#5e53e8",
    color: "white",
    padding: "10px 10px",
    borderRadius: "30px",
    textAlign: "center",
    marginBottom: "3vh",
    width: "140px",
    ":hover": { backgroundColor: "#330785", color: "white" },
  },
  p: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  span: {
    fontSize: "10px",
  },
  workSheet: {
    fontWeight: "500",
    alignSelf: "center",
    textAlign: "center",
    fontSize: "large",
  },
  selectedCol: {
    borderLeft: "1px solid lightgray",
    borderBottom: "1px solid lightgray",
    "&:hover": {
      button: {
        display: "block",
      },
    },
    width: "100%",
  },
  editBtn: {
    display: "none",
  },
}));

const CellBox = ({ timesheets, index, id }) => {
  dayjs.extend(utc);
  const { classes } = useStyles();
  const uuid = useId(id);
  const { currentSchedule, setCurrentSchedule } = useContext(AppContext);

  const calculateColor = (single) => {
    if (currentSchedule?.id === single?.id) return "blue";
    else if (single.approved === true) return "green";
    else if (single.discard === true) return "red";
    else return "gray";
  };
  return (
    <Grid.Col span={2} className={classes.selectedCol}>
      {timesheets ? (
        timesheets?.map((single, i) => (
          <Button
            radius="lg"
            fullWidth
            my={2}
            color={calculateColor(single)}
            key={uuid + Math.random().toString()}
            onClick={() =>
              setCurrentSchedule({ index, id: single.id, childIndex: i })
            }
          >
            {dayjs(single.start_at).utc(false).format("HH:mm")}-{" "}
            {dayjs(single.end_at).utc(false).format("HH:mm")}
          </Button>
        ))
      ) : (
        <p key={uuid + Math.random().toString()}>No shifts</p>
      )}
    </Grid.Col>
  );
};

export default CellBox;
