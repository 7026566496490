import { Button, Group } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import React from "react";
import { useContext } from "react";
import { Edit, Trash } from "tabler-icons-react";
import { AppContext } from "../../context/AppContext";
import { useDeleteSchedule } from "./services/useDeleteSchedule";

const DateBox = ({ single, date, id }) => {
  const { hovered, ref } = useHover();
  dayjs.extend(utc);
  const { mutate, isLoading } = useDeleteSchedule();
  const { setAddScheduleModal } = useContext(AppContext);

  const handleDelete = () => {
    mutate(single.id);
  };

  const handleEdit = () => {
    console.log("edit");
    setAddScheduleModal({
      opened: true,
      editId: single?.id,
      id: single?.employeeId,
      date,
    });
  };

  return (
    <div ref={ref}>
      {hovered ? (
        <Group grow position="center" my={2} spacing={5}>
          <Button
            fullWidth
            variant="filled"
            color="teal"
            radius={"xl"}
            onClick={handleEdit}
          >
            <Edit size={18} />
          </Button>
          <Button
            fullWidth
            variant="filled"
            color="red"
            radius={"xl"}
            onClick={handleDelete}
          >
            <Trash size={18} />
          </Button>
        </Group>
      ) : (
        <Button ref={ref} fullWidth radius={"xl"} my={2} loading={isLoading}>
          <p style={{ fontSize: "12px" }}>
            {dayjs(single.start_at).utc().format("HH:mm")} -
            {dayjs(single.end_at).utc().format("HH:mm")}
          </p>
        </Button>
      )}
    </div>
  );
};

export default DateBox;
