import { createStyles, Grid } from "@mantine/core";
import { useId } from "@mantine/hooks";
import React from "react";
import AddBox from "./AddBox";
import DateBox from "./DateBox";
// import hero from './hero.png';

const useStyles = createStyles((theme, _params, getRef) => ({
  cellbox: {
    backgroundColor: "#5e53e8",
    color: "white",
    padding: "10px 10px",
    borderRadius: "30px",
    textAlign: "center",
    marginBottom: "3vh",
    width: "140px",
    ":hover": { backgroundColor: "#330785", color: "white" },
  },
  p: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  span: {
    fontSize: "10px",
  },
  workSheet: {
    fontWeight: "500",
    alignSelf: "center",
    textAlign: "center",
    fontSize: "large",
  },
  selectedCol: {
    borderLeft: "1px solid lightgray",
    borderBottom: "1px solid lightgray",
    "&:hover": {
      button: {
        display: "block",
      },
    },
    width: "100%",
  },
  editBtn: {
    display: "none",
  },
}));

const CellBox = ({ schedule, date, id }) => {
  const { classes } = useStyles();
  const uuid = useId(id);
  return (
    <Grid.Col span={2} className={classes.selectedCol}>
      {schedule?.map((single) => (
        <DateBox
          single={single}
          date={date}
          key={uuid + Math.random().toString()}
          id={id}
        />
      ))}
      <AddBox
        border={false}
        date={date}
        key={uuid + Math.random().toString()}
        id={id}
      />
    </Grid.Col>
  );
};

export default CellBox;
