import { useMutation, useQueryClient } from "react-query";
import { LOCATION_ROUTE } from "../../../utils/ApiRoutes";
import { showNotification } from "@mantine/notifications";
import { DeleteQuery } from "../../../utils/QueryCalls";


const DeleteLocation = async (id) => {
    return await DeleteQuery(LOCATION_ROUTE + "/" + id)
}

export const useDeleteLocation = () => {
    const queryClient = useQueryClient()

    return useMutation(DeleteLocation, {
        onSuccess: () => {
            queryClient.invalidateQueries(['locations'])
            showNotification({
                title: "Location removed",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Cannot remove location",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}