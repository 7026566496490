import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LOGINPAGE } from "./Constants";
import { checkTokenCookie } from "./utils";

const ProtectedRoute = ({ children }) => {
  if (checkTokenCookie() === false) {
    return <Navigate to={LOGINPAGE} replace />;
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
