import { Button, createStyles, Grid, TextInput } from "@mantine/core";
import { useId } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Copy, Plus, Search } from "tabler-icons-react";
import Dashboard from "../components/Dashboard/Dashboard";
import AddModal from "../components/Roaster/AddModal";
import AvatarBox from "../components/Roaster/AvatarBox";
import CellBox from "../components/Roaster/CellBox";
import { CopyShiftModal } from "../components/Roaster/CopyShiftModal";
import DateSelector from "../components/Roaster/DateSelector";
import RightHeader from "../components/Roaster/RightHeader";
import SiteLists from "../components/Roaster/SiteLists";
import { AppContext } from "../context/AppContext";
import { SCHEDULE_ROUTE } from "../utils/ApiRoutes";
import { FetchQueryWithParams } from "../utils/QueryCalls";

// import TimelineComp from '../components/Roaster/TimelineComp';

const useStyles = createStyles((theme, _params, getRef) => ({
  badge: {
    height: "2.5em",
    paddingTop: "0px",
  },
  container: {
    marginBottom: "2em",
  },

  togglebutton: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "0.3em",
  },
  togglebtncnt: {
    backgroundColor: "#f0ede9",
  },
  careleft: {
    background: "#d1cfcb",
    borderRadius: "50%",
  },
  title: {
    backgroundColor: "#F32424",
    color: "white",
    padding: "7px 20px",
    borderRadius: "20px",
    marginBottom: "2.1rem",
    textAlign: "center",
    width: "15%",
  },

  rightheader: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#001D6E",
    color: "white",
    padding: "10px 20px",
    borderBottomLeftRadius: "20px",
    borderTopLeftRadius: "20px",
    marginBottom: "1.5em",
  },

  roastersection: {
    // display: "flex",
    // justifyContent: "space-between",
  },
  leftsection: {
    width: "100%",
    // flexBasis: "17%",
    // border: "1px solid black"
  },
  rightsection: {
    // flexBasis: "81%",
    // border: "1px solid black"
  },
  rightcontent: {
    paddingRight: "1em",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(7,1fr)",
    columnGap: ".4rem",
    maxWidth: "100%",
    paddingLeft: "1em",
    marginLeft: "auto",
    marginRight: "auto",
  },
  gridMain: {
    border: "1px solid lightgray",
  },
  bar: {
    display: "flex",
    columnGap: "30px",
  },
}));

const RoasterPage = () => {
  // for multistep form
  const { currentLocationId } = useContext(AppContext);
  const [date, setDate] = useState({
    start: dayjs(new Date()).startOf("week"),
    end: dayjs(new Date()).endOf("week"),
  });
  const uuid = useId();
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const { data, isLoading } = useQuery(
    [
      "schedules",
      SCHEDULE_ROUTE,
      `?start_date=${dayjs(date.start).format("YYYY-MM-DD")}&end_date=${dayjs(
        date.end
      ).format("YYYY-MM-DD")}`,
      currentLocationId,
    ],
    FetchQueryWithParams,
    {
      enabled: !!currentLocationId,
      onSuccess: (data) => {},
    }
  );
  const ScheduleBoxes = (Schedule, id) => {
    let boxes = [];
    let check = 0;
    for (let index = 0; index <= 6; index++) {
      const currDate = dayjs(date.start).add(index, "d").format("YYYY-MM-DD");
      if (
        check <= Object.keys(Schedule).length - 1 &&
        currDate === Object.keys(Schedule)[check]
      ) {
        boxes.push(
          <CellBox
            schedule={Object.values(Schedule)[check]}
            date={currDate}
            id={id}
            key={uuid + Math.random().toString()}
            className="item"
          />
        );
        check++;
      } else {
        boxes.push(
          <CellBox
            date={currDate}
            id={id}
            key={uuid + Math.random().toString()}
          />
        );
      }
    }
    return boxes;
  };
  return (
    <Dashboard>
      <CopyShiftModal opened={opened} setOpened={setOpened} date={date} />

      <div className={classes.container}>
        <Grid gutter="xs" style={{ maxWidth: "100%" }}>
          <Grid.Col span={3}>
            <TextInput
              variant="filled"
              placeholder="Search Staff"
              icon={<Search size={20} />}
              radius="xl"
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <SiteLists />
          </Grid.Col>
          <Grid.Col span={3}>
            <DateSelector date={date} setDate={setDate} />
          </Grid.Col>
          <Grid.Col span={3}>
            <Button
              className={classes.badge}
              leftIcon={<Copy />}
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
              fullWidth
              radius="xl"
              onClick={() => setOpened(true)}
            >
              Copy Shift
            </Button>
          </Grid.Col>
        </Grid>
      </div>

      {/* <Container> */}
      <div className={classes.roastersection} style={{ width: "80vw" }}>
        <RightHeader date={date} setDate={setDate} />
        <Grid
          grow
          className={classes.gridMain}
          columns={17}
          style={{ marginTop: "7px", width: "100%" }}
        >
          {isLoading && <h1>Loading</h1>}
          {data
            ?.filter((e) =>
              e?.name?.toLowerCase().includes(searchVal.toLowerCase())
            )
            ?.map((emp) => (
              <React.Fragment key={uuid + Math.random().toString()}>
                <AvatarBox name={emp.name} />
                {ScheduleBoxes(emp?.Schedule, emp.id)}
              </React.Fragment>
            ))}
        </Grid>
      </div>

      <div style={{ padding: "10px", marginTop: "2em" }}></div>
      <AddModal />
      {/*
        </Container> */}
    </Dashboard>
  );
};

export default RoasterPage;
