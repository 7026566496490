import {
  Button,
  createStyles,
  Group,
  Input,
  Modal,
  SegmentedControl,
  Select,
  Space,
  Switch,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { useState } from "react";
import { ImSearch } from "react-icons/im";
import { IoAdd } from "react-icons/io5";
import { useAddEmployee } from "./services/usePostData";
const useStyles = createStyles((theme, _params, getRef) => {
  return {};
});
const EmpSection = ({ value, setValue, setSearchVal }) => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
      role: "EMPLOYEE",
      induction: true,
      faculty:"CLEANING"
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const { mutate, isLoading } = useAddEmployee();

  const handleAdd = (values) => {
    mutate(values, {
      onSuccess: () => {
        setOpened(false);
      },
    });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "1rem",
          gap: "2rem",
        }}
      >
        <Input
          id=""
          placeholder="Search Staff"
          icon={<ImSearch />}
          radius="lg"
          onChange={(e) => setSearchVal(e.target.value)}
        />
        <SegmentedControl
          value={value}
          onChange={setValue}
          color="indigo"
          radius="lg"
          data={[
            { label: "Active", value: "active" },
            { label: "All", value: "all" },
          ]}
        />
        <Modal
          centered
          opened={opened}
          onClose={() => setOpened(false)}
          title="Add Employee"
          size="md"
          radius="md"
          padding={20}
        >
          <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
            <TextInput
              required
              label="Email"
              placeholder="your@email.com"
              {...form.getInputProps("email")}
            />
            <Select
              required
              label="Role"
              placeholder="Pick one"
              data={[
                { value: "ADMIN", label: "Admin" },
                { value: "MANAGER", label: "Manager" },
                { value: "EMPLOYEE", label: "Employee" },
              ]}
              {...form.getInputProps("role")}
            />
            <Select
                required
                label="Faculty"
                placeholder="Pick one"
                data={[
                  { value: "CLEANING", label: "Hospitality" },
                  { value: "NURSING", label: "Nursing" },
                ]}
                {...form.getInputProps("faculty")}
            />
            <Switch
              mt="sm"
              defaultChecked
              label="Induction required"
              {...form.getInputProps("induction")}
            />
            <Space h={14} />
            <Group position="right" mt="md">
              <Button loading={isLoading} type="submit">
                Submit
              </Button>
            </Group>
          </form>
        </Modal>

        <Group position="center">
          <Button
            className={classes.modalBtn}
            onClick={() => setOpened(true)}
            leftIcon={<IoAdd size={20} />}
            radius="xl"
            styles={(theme) => ({
              root: {
                backgroundColor: "#FF6A3B",
                height: 32,
                paddingLeft: 10,
                paddingRight: 60,

                "&:hover": {
                  backgroundColor: theme.fn.darken("#FF6A3B", 0.05),
                },
              },
            })}
          >
            Add
          </Button>
        </Group>
      </div>
    </>
  );
};

export default EmpSection;
