const apiUrl = "https://api.skclabourhire.com.au/api/";
// const apiUrl = "http://localhost:8000/api/";
export const MapsApi = "AIzaSyAoSQNf0FfjtN0UZXoGE3zTSpMDyNxu-M4";

export const apiUrlAbsolute = "http://localhost:8000/";
export const LOGIN_ROUTE = apiUrl + "auth/admin";
export const LOGOUT_ROUTE = apiUrl + "auth/logout";
export const USER_ROUTE = apiUrl + "auth/me";
export const EMPLOYEE_ROUTE = apiUrl + "employees";
export const SCHEDULE_ROUTE = apiUrl + "schedules";
export const TIMESHEET_ROUTE = apiUrl + "timesheets";
export const PROFILE_ROUTE = apiUrl + "profile";
export const SCHEDULE_COPY_ROUTE = apiUrl + "schedules/copy/nextweek";
export const SCHEDULE_COPY_SPECIFIC_ROUTE = apiUrl + "schedules/copy";
export const LOCATION_ROUTE = apiUrl + "locations";
export const MESSAGE_ROUTE = apiUrl + "messages";
export const NOTIFICATION_ROUTE = apiUrl + "notifications";
export const WORDPRESS_ROUTE = apiUrl + "wordpress";
