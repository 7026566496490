/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Modal } from "@mantine/core";
import dayjs from "dayjs";
import React, { useContext, useEffect, useId, useState } from "react";
import { useQuery } from "react-query";
import { AppContext } from "../../context/AppContext";
import { EMPLOYEE_ROUTE } from "../../utils/ApiRoutes";
import { FetchQuery } from "../../utils/QueryCalls";
import CellBox from "./CellBox";
import DateSelector from "./DateSelector";
import RightHeader from "./RightHeader";
function RosterModal({ selectedEmployee }) {
  const { employeeRosterModal, setEmployeeRosterModal } =
    useContext(AppContext);
  const [date, setDate] = useState({
    start: dayjs(new Date()).startOf("week"),
    end: dayjs(new Date()).endOf("week"),
  });
  const uuid = useId();
  const { data, refetch } = useQuery(
    ["employee_roster"],
    () =>
      FetchQuery(
        EMPLOYEE_ROUTE +
          "/" +
          selectedEmployee +
          `/schedule?start_date=${dayjs(date.start).format(
            "YYYY-MM-DD"
          )}&end_date=${dayjs(date.end).format("YYYY-MM-DD")}`
      ),
    {
      enabled: false,
    }
  );
  useEffect(() => {
    refetch();
  }, [date]);

  const ScheduleBoxes = (Schedule, id) => {
    let boxes = [];
    let check = 0;
    console.log(Object.keys(Schedule));
    for (let index = 0; index <= 6; index++) {
      const currDate = dayjs(date.start).add(index, "d").format("YYYY-MM-DD");
      if (
        check <= Object.keys(Schedule).length - 1 &&
        currDate === Object.keys(Schedule)[check]
      ) {
        boxes.push(
          <CellBox
            schedule={Object.values(Schedule)[check]}
            date={currDate}
            id={id}
            key={uuid + Math.random().toString()}
            className="item"
          />
        );
        check++;
      } else {
        boxes.push(
          <CellBox
            date={currDate}
            id={id}
            key={uuid + Math.random().toString()}
          />
        );
      }
    }
    return boxes;
  };

  return (
    <Modal
      opened={employeeRosterModal.opened}
      onClose={() => setEmployeeRosterModal({ opened: false })}
      title="Roster"
      size={"70%"}
    >
      <DateSelector date={date} setDate={setDate} />
      <div>
        <RightHeader date={date} setDate={setDate} />
        <Grid grow columns={17} style={{ marginTop: "7px", width: "100%" }}>
          <React.Fragment key={uuid + Math.random().toString()}>
            {data && ScheduleBoxes(data, selectedEmployee)}
          </React.Fragment>
        </Grid>
      </div>
    </Modal>
  );
}
export default RosterModal;
