import React from "react";
import {
  Button,
  Card,
  Grid,
  Group,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import { useQuery } from "react-query";
import { FetchQuery } from "../../utils/QueryCalls";
import { EMPLOYEE_ROUTE } from "../../utils/ApiRoutes";
import { Link } from "react-router-dom";

const EmployeeList = () => {
  const { data } = useQuery(["employees"], () =>
    FetchQuery(EMPLOYEE_ROUTE + "/all")
  );
  const rows = data?.slice(0, 8)?.map((emp) => (
    <tr key={emp.employee.id}>
      <td>{emp?.employee?.name || "Requested"}</td>
      <td>{emp.role}</td>
      <td>{emp.employee.active ? "Active" : "Inactive"}</td>
    </tr>
  ));
  return (
    <Grid.Col span={5}>
      <Card
        shadow="xs"
        p="lg"
        radius="lg"
        withBorder
        style={{ height: "100%" }}
      >
        <Card.Section withBorder inheritPadding p="md">
          <Group position="apart">
            <Text weight={500}>Employee List</Text>
            <Link to="/app/employee">
              <Button variant="filled" color="blue" radius="lg">
                View all
              </Button>
            </Link>
          </Group>
        </Card.Section>
        <Table>
          <ScrollArea style={{ height: 300 }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </ScrollArea>
        </Table>
      </Card>
    </Grid.Col>
  );
};

export default EmployeeList;
