import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import { NOTIFICATION_ROUTE } from "../../../utils/ApiRoutes";
import { PostQuery } from "../../../utils/QueryCalls";

const sendNotification = async (data) => {
    return await PostQuery(NOTIFICATION_ROUTE + "/send", data)
}
export const useSendNotification = () => {
    const queryClient = useQueryClient()

    return useMutation(sendNotification, {
        onSuccess: () => {
            queryClient.invalidateQueries(['activeEmployees']);
            queryClient.invalidateQueries(['employees']);
            showNotification({
                title: "Notification sent",
                color: 'green'
            })
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}
