import {
  Button,
  createStyles,
  Grid,
  Space,
  Text,
  TextInput,
  Loader,
  Center,
} from "@mantine/core";
import { useId } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Plus, Search } from "tabler-icons-react";
import Dashboard from "../components/Dashboard/Dashboard";
import DateSelector from "../components/Roaster/DateSelector";
import RightHeader from "../components/Timesheet/RightHeader";
import AvatarBox from "../components/Timesheet/AvatarBox";
import BarGraph from "../components/Timesheet/BarGraph";
import PreviousRecord from "../components/Timesheet/PreviousRecord";
import { EMPLOYEE_ROUTE, TIMESHEET_ROUTE } from "../utils/ApiRoutes";
import { FetchQuery, FetchTimeSheet } from "../utils/QueryCalls";
import CellBox from "../components/Timesheet/CellBox";
import RingTime from "../components/Timesheet/RingTime";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useApproveTimesheet } from "../components/Timesheet/services/useApproveTimesheet";
import { useApproveAllTimesheet } from "../components/Timesheet/services/useApproveAll";
import { useApproveEveryoneTimesheet } from "../components/Timesheet/services/useApproveEveryone";
import { useDiscardTimesheet } from "../components/Timesheet/services/useDiscardTimeSheet";

const useStyles = createStyles((theme, _params, getRef) => ({
  badge: {
    height: "2.5em",
    paddingTop: "0px",
  },
  container: {
    marginBottom: "1em",
  },

  togglebutton: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "0.3em",
  },
  togglebtncnt: {
    backgroundColor: "#f0ede9",
  },
  careleft: {
    background: "#d1cfcb",
    borderRadius: "50%",
  },
  title: {
    backgroundColor: "#F32424",
    color: "white",
    padding: "5px 20px",
    borderRadius: "20px",
    marginBottom: "2em",
    textAlign: "center",
  },

  timesheetsection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  leftsection: {
    flexBasis: "17%",

    // border: "1px solid black"
  },
  leftcontent: {
    display: "flex",
    height: "90%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  approveall: {
    alignSelf: "flex-end",
  },
  rightsection: {
    flexBasis: "81%",
    paddingLeft: "1rem",
  },
  rightcontent: {
    paddingRight: ".5em",
  },

  // timesheet time section and previous record style start here
  timesection: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "1em",
  },
  timesection1: {
    flexBasis: "50%",
  },
  previousrecord: {
    flexBasis: "45%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "15px",
  },
  row1: {
    marginTop: "1em",
    padding: "10px 10px",
    borderRadius: "15px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(6,1fr)",
    columnGap: ".4rem",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const TimeSheetPage = () => {
  const { currentSchedule, setCurrentSchedule } = useContext(AppContext);
  const { classes } = useStyles();
  const [searchVal, setSearchVal] = useState("");
  const uuid = useId();
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [date, setDate] = useState({
    start: dayjs(new Date()).startOf("week"),
    end: dayjs(new Date()).endOf("week"),
  });
  const { mutate: approve } = useApproveTimesheet();
  const { mutate: discard } = useDiscardTimesheet();
  const { mutate: approveAll } = useApproveAllTimesheet();
  const { mutate: approveEveryone } = useApproveEveryoneTimesheet();
  const { isLoading: employeeLoading, data: employees } = useQuery(
    ["employees"],
    () => FetchQuery(EMPLOYEE_ROUTE),
    {
      onSuccess: (data) => {
        if (!currentEmployee) {
          setCurrentEmployee(data[0].employee.id);
        }
      },
    }
  );

  const { data, isLoading, fetchStatus } = useQuery(
    [
      "Timesheets",
      TIMESHEET_ROUTE,
      `?start_date=${dayjs(date.start).format("YYYY-MM-DD")}&end_date=${dayjs(
        date.end
      ).format("YYYY-MM-DD")}`,
      currentEmployee,
    ],
    FetchTimeSheet,
    {
      enabled: !!currentEmployee,
      onSuccess: (data) => {
        if (currentSchedule === null && Object.keys(data.schedule).length > 0) {
          setCurrentSchedule({
            id: Object.values(data.schedule)[0][0].id,
            index: 0,
            childIndex: 0,
          });
        }
      },
    }
  );

  const TimeSheetBoxes = (timesheet, id) => {
    let boxes = [];
    let check = 0;
    for (let index = 0; index <= 6; index++) {
      const currDate = dayjs(date.start).add(index, "d").format("YYYY-MM-DD");
      if (
        check <= Object.keys(timesheet).length - 1 &&
        currDate === Object.keys(timesheet)[check]
      ) {
        boxes.push(
          <CellBox
            timesheets={Object.values(timesheet)[check]}
            date={currDate}
            id={id}
            key={uuid + Math.random().toString()}
            index={check}
            className="item"
          />
        );
        check++;
      } else {
        boxes.push(
          <CellBox
            id={id}
            key={uuid + Math.random().toString()}
            className="item"
          />
        );
      }
    }
    return boxes;
  };
  return (
    <Dashboard>
      <div className={classes.container}>
        <Grid gutter="xs" style={{ maxWidth: "100%" }}>
          <Grid.Col span={4}>
            <TextInput
              variant="filled"
              placeholder="Search Staff"
              icon={<Search size={20} />}
              radius="xl"
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Grid.Col>

          <Grid.Col span={4}>
            <div className={classes.togglebutton}>
              <DateSelector date={date} setDate={setDate} />
            </div>
          </Grid.Col>
          <Grid.Col span={4}>
            <Button
              className={classes.badge}
              variant="gradient"
              gradient={{ from: "#23B480", to: "#23B480" }}
              fullWidth
              radius="xl"
              onClick={() =>
                approveEveryone({
                  query: `?start_date=${dayjs(date.start).format(
                    "YYYY-MM-DD"
                  )}&end_date=${dayjs(date.end).format("YYYY-MM-DD")}`,
                })
              }
            >
              Approve Everyone
            </Button>
          </Grid.Col>
        </Grid>
      </div>

      {/* <Container > */}

      <div className={classes.timesheetsection}>
        <div className={classes.leftsection}>
          <div className={classes.leftcontent}>
            <Grid mr="5px">
              {!employeeLoading &&
                employees
                  ?.filter((e) =>
                    e?.employee?.name
                      ?.toLowerCase()
                      .includes(searchVal.toLowerCase())
                  )
                  ?.map((emp) => (
                    <AvatarBox
                      key={emp?.id}
                      name={emp?.employee?.name}
                      active={currentEmployee === emp?.employee?.id}
                      setCurrentEmployee={setCurrentEmployee}
                      empId={emp?.employee?.id}
                    />
                  ))}
            </Grid>
          </div>
        </div>
        <div className={classes.rightsection}>
          {!isLoading && !employeeLoading && data?.schedule ? (
            <>
              <RightHeader date={date} setDate={setDate} />
              <Grid
                grow
                className={classes.gridMain}
                columns={17}
                style={{ marginTop: "7px", marginBottom: "7px", width: "100%" }}
              >
                {isLoading && <h1>Loading</h1>}
                {data && TimeSheetBoxes(data?.schedule)}
              </Grid>
              <div>
                <Grid align="center" gutter="xl">
                  <Grid.Col span={5}>
                    <Button
                      fullWidth
                      color="blue"
                      variant="filled"
                      size="sm"
                      radius="xl"
                    >
                      Total hour this week: {data?.totalHours} hours
                    </Button>
                  </Grid.Col>
                  <Grid.Col span={5}>
                    <Button
                      fullWidth
                      variant="gradient"
                      gradient={{ from: "#23B480", to: "#23B480" }}
                      size="sm"
                      radius="xl"
                      onClick={() =>
                        approveAll({
                          query: `?start_date=${dayjs(date.start).format(
                            "YYYY-MM-DD"
                          )}&end_date=${dayjs(date.end).format("YYYY-MM-DD")}`,
                          id: currentEmployee,
                        })
                      }
                    >
                      Approve Current Week
                    </Button>
                  </Grid.Col>
                </Grid>
                <Grid align="center" gutter="xl">
                  <Grid.Col span={5}>
                    {fetchStatus !== "fetching" &&
                    Object.keys(data?.schedule).length > 0 ? (
                      <Button
                        fullWidth
                        variant="gradient"
                        gradient={{ from: "#23B480", to: "#23B480" }}
                        size="sm"
                        radius="xl"
                        disabled={
                          Object.values(data?.schedule)[currentSchedule?.index][
                            currentSchedule?.childIndex
                          ]?.approved ||
                          Object.values(data?.schedule)[currentSchedule?.index][
                            currentSchedule?.childIndex
                          ]?.discard ||
                          false
                        }
                        onClick={() => approve({ id: currentSchedule.id })}
                      >
                        {Object.values(data?.schedule)[currentSchedule?.index][
                          currentSchedule?.childIndex
                        ].approved
                          ? "Approved"
                          : "Approve"}
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        variant="filled"
                        color="green"
                        size="sm"
                        radius="xl"
                        disabled={true}
                      >
                        Approve
                      </Button>
                    )}
                  </Grid.Col>
                  <Grid.Col span={5}>
                    {fetchStatus !== "fetching" &&
                    Object.keys(data?.schedule).length > 0 ? (
                      <Button
                        fullWidth
                        variant="filled"
                        color="red"
                        size="sm"
                        radius="xl"
                        disabled={
                          Object.values(data?.schedule)[currentSchedule?.index][
                            currentSchedule?.childIndex
                          ]?.discard ||
                          Object.values(data?.schedule)[currentSchedule?.index][
                            currentSchedule?.childIndex
                          ]?.approved ||
                          false
                        }
                        onClick={() => discard({ id: currentSchedule.id })}
                      >
                        {Object.values(data?.schedule)[currentSchedule?.index][
                          currentSchedule?.childIndex
                        ].discard
                          ? "Discarded"
                          : "Discard"}
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        variant="filled"
                        color="red"
                        size="sm"
                        radius="xl"
                        disabled={true}
                      >
                        Discard
                      </Button>
                    )}
                  </Grid.Col>
                </Grid>
              </div>
              <div className={classes.timesection}>
                <div className={classes.timesection1}>
                  <div className={classes.row1}>
                    {fetchStatus !== "fetching" &&
                      (Object.keys(data?.schedule).length > 0 &&
                      currentSchedule ? (
                        <RingTime
                          name={data?.employee?.name}
                          data={
                            Object.values(data?.schedule)[
                              currentSchedule?.index
                            ][currentSchedule?.childIndex]
                          }
                        />
                      ) : (
                        <Text
                          align="center"
                          weight={"500"}
                          size="lg"
                          color={"red"}
                        >
                          No shifts in current week
                        </Text>
                      ))}
                  </div>
                  <div className={classes.row1}>
                    <BarGraph data={data?.thisWeek} />
                  </div>
                </div>
                <div className={classes.previousrecord}>
                  <Space h="lg" />
                  <PreviousRecord currentEmployee={currentEmployee} />
                </div>
              </div>
            </>
          ) : (
            <Center>
              <Loader />
            </Center>
          )}
        </div>
        <div
          className={classes.approveall}
          style={{ padding: "10px", marginTop: "2em" }}
        ></div>
      </div>

      {/* </Container> */}
    </Dashboard>
  );
};

export default TimeSheetPage;
