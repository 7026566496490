import { ActionIcon, Avatar, Container, Space, Text } from "@mantine/core";
import React from "react";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
import { Edit } from "tabler-icons-react";

const EmployeeCard = ({
  setOpened,
  employee,
  setSelectedEmployee,
  setRoleModal,
}) => {
  return (
    <Container
      onClick={() => {
        setOpened(true);
        setSelectedEmployee(employee.id);
      }}
      style={{
        borderRadius: "25px",
        boxShadow: "rgba(0, 0, 0, 0.21) 3px 5px 3.2px",
        padding: "2rem 2rem 1rem 2rem",
        cursor: "pointer",
        width: "100%",
      }}
    >
      <Avatar
        radius={100}
        color="cyan"
        size={80}
        style={{
          margin: "0 auto",
        }}
      />
      <Space h={15} />
      <h3 style={{ color: "black", textAlign: "center" }}>
        {employee.employee.name}
      </h3>
      <Text align="center" color="#696969" size="md">
        {employee.role}
      </Text>
        <Text align="center" color="#696969" size="md">
        {employee.Faculty==="CLEANING"?"Hospitality":employee.Faculty}
      </Text>
      <Space h={14} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <div
          style={{
            background: "#0E0C56",
            height: "36px",
            width: "36px",
            borderRadius: "50%",
            display: "grid",
            justifyContent: "center",
            placeItems: "center",
          }}
        >
          {" "}
          <AiFillPhone size={21} color="white" />
        </div>
        <div
          style={{
            background: "#0E0C56",
            height: "36px",
            width: "36px",
            borderRadius: "50%",
            display: "grid",
            justifyContent: "center",
            placeItems: "center",
          }}
        >
          <AiOutlineMail size={21} color="white" />
        </div>
      </div>
    </Container>
  );
};

export default EmployeeCard;
