/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Select, Space, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useContext, useEffect, useState, useRef } from "react";
import { Copy } from "tabler-icons-react";
import { AppContext } from "../../context/AppContext";
import {
  useCopyScheduleNextWeek,
  useCopyScheduleSpecificWeek,
} from "../Employee/services/usePostData";

export function CopyShiftModal({ opened, setOpened, date }) {
  const { locations, currentLocationId } = useContext(AppContext);
  const [dates, setDates] = useState([]);
  const [error, setError] = useState("");
  const [openedCalendar, setOpenedCalender] = useState(false);
  const { mutate } = useCopyScheduleNextWeek();
  const { mutate: copySpecific } = useCopyScheduleSpecificWeek();
  const selectedDate = useRef(null);
  const [targetLocationId, setTargetLocationId] = useState(0);

  const handleNextWeek = () => {
    mutate(
      { location_id: currentLocationId, date: dayjs(date.start).utc(true) },
      {
        onSuccess: () => {
          setOpened(false);
        },
        onError: (error) => {
          console.log(error);
          setError("Error");
        },
      }
    );
  };

  const handleCopySpecific = () => {
    const index = dates.findIndex((e) => e.value === selectedDate.current);
    const indexedDate = dates[index];
    console.log(indexedDate);
    copySpecific(
      {
        location_id: currentLocationId,
        from: dayjs(date.start).utc(true),
        to: indexedDate.date[1],
        targetLocationId,
      },
      {
        onSuccess: () => {
          setOpened(false);
          setOpenedCalender(false);
        },
        onError: (error) => {
          console.log(error);
          setError("Error");
        },
      }
    );
  };

  const calculateDate = () => {
    const days = [];
    for (let index = 0; index < 140; index += 7) {
      let day = "";
      day = {
        date: [
          dayjs(date.start).add(index, "d").startOf("w").format("YYYY-MM-DD"),
          dayjs(date.start).add(index, "d").endOf("w").format("YYYY-MM-DD"),
        ],
        value: `${dayjs(date.start)
          .add(index, "d")
          .startOf("w")
          .format("MMM DD")} - ${dayjs(date.start)
          .add(index, "d")
          .endOf("w")
          .format("MMM DD")}`,
        label: `${dayjs(date.start)
          .add(index, "d")
          .startOf("w")
          .format("MMM DD")} - ${dayjs(date.start)
          .add(index, "d")
          .endOf("w")
          .format("MMM DD")}`,
      };
      days.push(day);
    }
    setDates(days);
  };
  useEffect(() => {
    calculateDate();
  }, []);

  const handleOpenMultiCalender = () => {
    calculateDate();
    setOpenedCalender(true);
  };
  return (
    <>
      <Modal centered opened={opened} onClose={() => setOpened(false)}>
        <Button
          leftIcon={<Copy />}
          size="md"
          variant="gradient"
          gradient={{ from: "green", to: "green" }}
          fullWidth
          radius="xl"
          onClick={handleNextWeek}
        >
          Copy Shift to Next Week
        </Button>
        <Space h="sm" />
        <Text align="center" size="lg">
          Or
        </Text>
        <Space h="sm" />
        <Button
          size="md"
          leftIcon={<Copy />}
          variant="gradient"
          gradient={{ from: "gray", to: "red" }}
          fullWidth
          radius="xl"
          onClick={handleOpenMultiCalender}
        >
          {selectedDate.current || "Copy Shift to Others Week"}
        </Button>
      </Modal>
      <Modal
        opened={openedCalendar}
        onClose={() => setOpenedCalender(false)}
        size="sm"
      >
        <Select
          label="Copy Shift"
          placeholder="Pick dates range"
          data={dates}
          radius="xl"
          my={10}
          required
          onChange={(val) => (selectedDate.current = val)}
        />
        {locations?.length > 0 && (
          <Select
            placeholder="Select location"
            data={locations}
            radius="xl"
            my={10}
            defaultValue={0}
            required
            onChange={(val) => setTargetLocationId(val)}
          />
        )}
        <Button
          size="sm"
          mt={5}
          variant="filled"
          fullWidth
          radius="xl"
          onClick={handleCopySpecific}
        >
          Submit
        </Button>
      </Modal>
    </>
  );
}
